import { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import { Text } from '@ftdr/blueprint-components-react';
import { Header } from '../components/header';
import { Footer } from '../components/footer';
import { SimpleBooking } from '../components/simple-booking';
import { IServiceType } from '../interfaces/shared';
import { IFooterLink } from '../interfaces/footer';
import { getFooterLinks } from '../services/zesty';
import styles from './404.module.css';
import { PagesContext } from '../state/pages.context';

function Page404() {
    const [services, setServices] = useState<IServiceType[]>([]);
    const [footerLinks, setFooterLinks] = useState<IFooterLink[]>([]);
    const { userMarket } = useContext(PagesContext);

    useEffect(() => {
        (async () => {
            const localFooterLinks = await getFooterLinks();

            const localServices: IServiceType[] = await fetch(
                'https://proconnect-qpk2fb6f.zesty.dev/proconnect/services.json',
            )
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    return response.data;
                });

            const result = localServices.filter((service) => !!service.enabled);

            setServices(result);
            setFooterLinks(localFooterLinks);
        })();
    }, []);

    return (
        <>
            <Header pageTitle="404 page" />

            <div
                className={clsx(
                    'text-center mb-40 py-10',
                    styles['page404-container'],
                )}
            >
                <Text
                    variant="heading-03"
                    color="primary"
                    className="mt-20 mb-2"
                >
                    This page may have moved or is no longer available.
                </Text>

                <Text
                    variant="heading-06"
                    className={clsx(styles['subtitle-page404'], 'mb-9')}
                >
                    We&#39;re sorry, but we can&#39;t find the page you
                    requested (Error 400).
                </Text>

                <Text variant="heading-04" color="primary" className="mb-6">
                    Looking to book a service?
                </Text>

                <div
                    className={clsx(
                        styles['booking-widget'],
                        'relative w-full flex justify-center',
                    )}
                >
                    <div className="absolute w-full max-w-xl">
                        <SimpleBooking
                            services={services}
                            hideTitle
                            forceMobile
                        />
                    </div>
                </div>

                <Text
                    variant="label"
                    className={clsx(
                        styles['subtitle-page404'],
                        'text-center mt-4',
                    )}
                >
                    If you need assistance please call (855) 427-0092 <br /> or
                    book your next service below.
                </Text>
            </div>

            <Footer links={footerLinks} market={userMarket} />
        </>
    );
}

export default Page404;
