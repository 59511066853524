import {
    Text,
    Select,
    Button,
    useIsMobile,
    ProgressIndicatorOverlayStateSetterContext,
    SelectOption,
} from '@ftdr/blueprint-components-react';
import { useContext, useEffect, useState } from 'react';
import { InputWithError } from '../shared';
import styles from './simple-booking.module.css';
import Router from 'next/router';
import React from 'react';
import { IServiceType } from '../../interfaces/shared';
import { getMarketByZipCodeClient } from '../../services/market-client';
import { getBookingLink, slugify } from '../../shared/helpers';
import { PagesContext } from '../../state/pages.context';

interface ISimpleBooking {
    forceMobile?: boolean;
    services: IServiceType[];
    hideTitle?: boolean;
}

function SimpleBooking({
    hideTitle,
    services,
    forceMobile = false,
}: ISimpleBooking) {
    const [zipCode, setZipCode] = useState({ value: '', error: false });
    const { startLoading, endLoading } = useContext(
        ProgressIndicatorOverlayStateSetterContext,
    );
    const { userMarket } = useContext(PagesContext);
    const isMobile = useIsMobile();
    const [service, setService] = useState({ label: '', value: '' });

    useEffect(() => {
        const service = services.find((s) => s.id == '1013');
        service &&
            setService({
                label: service.name,
                value: `${service.id}`,
            });
    }, [services]);
    const handleFormSubmit = () => {
        (async () => {
            startLoading();
            if (zipCode.value.trim()) {
                try {
                    const response = await getMarketByZipCodeClient(
                        zipCode.value,
                    );
                    const path = `/${service?.label
                        .toLowerCase()
                        .replace(' ', '-')}-${slugify(
                        response?.superMarket?.marketName,
                    )}/service/${response?.superMarket?.marketLegacyId}-${
                        service.value
                    }?purge=true&refType=1`;
                    Router.push(path);
                } catch (error) {
                    setZipCode({ ...zipCode, error: true });
                }
            }
            endLoading();
        })();
    };

    const handleServiceSelect = (selectedOption: any) => {
        setService({ ...selectedOption });
    };

    return (
        <div className={styles['mobile_hero_booking']}>
            {!hideTitle && (
                <Text
                    variant="heading-02"
                    className={`${styles['header']} uppercase text-white mb-1 md:mb-4 text-8 md:text-9`}
                >
                    Home Services On-Demand
                </Text>
            )}

            {isMobile || forceMobile ? (
                <Button
                    color="secondary"
                    label="Get Started"
                    variant="filled"
                    size="large"
                    className="mt-1"
                    href={getBookingLink({
                        market: userMarket,
                    })}
                />
            ) : (
                <div
                    className={`${styles['container']} bg-primary-800 rounded-4 p-2`}
                >
                    <div className="flex items-center place-content-evenly">
                        <Text variant="heading-05" className={`text-white`}>
                            I need
                        </Text>

                        <div>
                            <Select
                                className="w-48"
                                label=""
                                options={
                                    services.map((s) => ({
                                        value: `${s.id}`,
                                        label: s.name,
                                    })) as SelectOption[]
                                }
                                onSelect={handleServiceSelect}
                                selected={service}
                                formField={true}
                                matchWidth={false}
                                placeholder="Select Service"
                            />
                        </div>

                        <Text variant="heading-05" className={`text-white`}>
                            in
                        </Text>
                        <div>
                            <InputWithError
                                value={zipCode.value}
                                name="zipcode"
                                id="zipcode"
                                size={'medium'}
                                formField
                                formFieldClassName={'my-4 flex-1'}
                                className="w-24"
                                label=""
                                placeholder="Zip Code"
                                inputMode="text"
                                type="text"
                                onChange={(e) =>
                                    setZipCode({
                                        value: e.target.value,
                                        error: false,
                                    })
                                }
                                showErrorBorder={zipCode.error}
                                showErrorMsg={false}
                            />
                        </div>
                        <Button
                            color="secondary"
                            label="GO"
                            variant="filled"
                            size="medium"
                            className="mt-1"
                            onClick={handleFormSubmit}
                        />
                    </div>
                    {zipCode.error && (
                        <Text
                            variant="caption"
                            color="secondary"
                            className="mb-2"
                        >
                            Oh no! It looks like we do not currently offer this
                            service in your zip code.
                        </Text>
                    )}
                </div>
            )}
        </div>
    );
}

export { SimpleBooking };
