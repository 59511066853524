export const FTDRLogo = () => (
    <svg
        width="60"
        height="60"
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_2480_141)">
            <path
                d="M56.64 60H3.36C1.512 60 0 58.488 0 56.64V3.36C0 1.512 1.512 0 3.36 0H56.64C58.488 0 60 1.512 60 3.36V56.64C60 58.488 58.488 60 56.64 60Z"
                fill="#F34113"
            />
            <path
                d="M48.792 48.3361H39.36V30.4561C39.36 25.2961 35.16 21.1201 30.024 21.1201C24.888 21.1201 20.688 25.3201 20.688 30.4561V48.3361H11.208V30.4561C11.208 20.0881 19.632 11.6641 30 11.6641C40.368 11.6641 48.792 20.0881 48.792 30.4561V48.3361Z"
                fill="white"
            />
            <path
                d="M36.6719 36.144C36.6719 37.8 35.3279 39.144 33.6719 39.144C32.0159 39.144 30.6719 37.8 30.6719 36.144C30.6719 34.488 32.0159 33.144 33.6719 33.144C35.3279 33.144 36.6719 34.488 36.6719 36.144Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_2480_141">
                <rect width="60" height="60" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
