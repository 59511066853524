import {
    Button,
    IconArrowRightCircled,
    IconPhone,
    IconQuestionMarkCircle,
    Link,
    Text,
} from '@ftdr/blueprint-components-react';
import clsx from 'clsx';
import getConfig from 'next/config';
import styles from './header-bubble.module.css';

interface Props {
    variant?: 'call-line' | 'faq' | 'book-button';
    isFTDRTheme?: boolean;
}

export function HeaderBubble({ variant = 'book-button', isFTDRTheme }: Props) {
    const {
        publicRuntimeConfig: { FORMATTED_PHONE = '' },
    } = getConfig();

    const variantComponentMap = {
        'call-line': (
            <div className="text-right">
                <Text
                    variant="heading-06"
                    color="primary"
                    className="hidden md:block"
                >
                    Yes we&apos;re open!
                </Text>
                <div>
                    <Link
                        className={clsx(
                            'flex justify-end',
                            isFTDRTheme && 'items-end',
                        )}
                        href={`tel:${FORMATTED_PHONE || '(844) 473-7849'}`}
                        color="primary"
                    >
                        <>
                            <IconPhone
                                size={isFTDRTheme ? 25 : 15}
                                color="primary"
                            />
                            <Text
                                className={clsx(
                                    'ml-1',
                                    isFTDRTheme && 'ml-2',
                                    isFTDRTheme && styles['caption-ftdr'],
                                )}
                                color="primary"
                                variant="caption"
                            >
                                <strong>
                                    {FORMATTED_PHONE || '(844) 473-7849'}
                                </strong>
                            </Text>
                        </>
                    </Link>
                </div>
            </div>
        ),
        'faq': (
            <div className="text-right">
                <Text
                    variant="heading-06"
                    color="primary"
                    className={clsx(isFTDRTheme && styles['questions-ftdr'])}
                >
                    Got Questions?
                </Text>
                <div className="flex justify-end">
                    <Link
                        className={clsx(
                            'ml-1 pr-1 text-right text-1',
                            isFTDRTheme && 'ml-2',
                        )}
                        startIcon={
                            <IconQuestionMarkCircle
                                size={isFTDRTheme ? 25 : 15}
                            />
                        }
                        bold
                        color="primary"
                        href="/faq/"
                    >
                        See our FAQs
                    </Link>
                </div>
            </div>
        ),
        'book-button': (
            <Button
                label="Book Online"
                variant="filled"
                endIcon={<IconArrowRightCircled />}
                size="small"
                shape="rounded"
                color="secondary"
            />
        ),
    };

    return (
        <div className="bg-white w-32 text-right ml-9">
            {variantComponentMap[variant]}
        </div>
    );
}
