import {
    DEFAULT_BREAKPOINTS,
    Link,
    useMatchMediaQueryPreset,
} from '@ftdr/blueprint-components-react';
import { RatingsBubble } from '../ratings-bubble';
import { HeaderBubble } from '../header-bubble';
import { useRouter } from 'next/router';
import {
    formatNumberWithCommas,
    getMarketId,
    getRatingsValues,
    getServiceId,
    getUrlQueryString,
    getWorkingHoursHelper,
} from '../../shared/helpers';
import { DEFAULT_MARKET_ID } from '../../shared/constants';
import { utcToZonedTime } from 'date-fns-tz';
import { isWithinWorkingHours } from './helpers';
import { NextHead } from '../head';
import { IHead } from '../../interfaces/shared-props';
import { AhsLogoDesk } from '../default-header/ahsLogoDesk';
import { AhsLogoNotDesk } from '../default-header/ahsLogoNotDesk';
import { useEffect, useState } from 'react';
import { IWorkingHours } from '../../interfaces/zesty';
import { FTDRLogo } from '../../svgs/ftdr-logo';
import styles from './header.module.css';
import clsx from 'clsx';

function Header({
    pageTitle,
    pageDescription,
    titleLeft,
    showLogoOnly,
    shouldIndexPage,
    isFTDRTheme,
}: IHead) {
    const router = useRouter();
    const urlServiceId = router.query?.service_id as string | undefined;
    const marketId = getMarketId(urlServiceId);
    const serviceId = getServiceId(urlServiceId);
    const { averageRating, totalReviews } = getRatingsValues(serviceId);

    const URLParams: Record<string, string | number> = {};

    const queryString = router.asPath.split('?')[1]
        ? router.asPath.split('?')[1]
        : '';

    Object.entries(router.query).forEach(([key, value]) => {
        if (queryString.includes(key) && key != 'superm')
            URLParams[key] = `${value}`;
    });

    const URLRecords = getUrlQueryString(URLParams);

    const logoUrl = `/${URLRecords != '' ? URLRecords + '&' : '?'}superm=${
        router.query?.superm || marketId || DEFAULT_MARKET_ID
    }`;

    const isDesktop = useMatchMediaQueryPreset(
        'lg-and-above',
        DEFAULT_BREAKPOINTS,
        'lg',
    );

    const [workingHours, setWorkingHours] = useState<IWorkingHours | null>(
        null,
    );

    useEffect(() => {
        (async () => {
            const workingHours = await getWorkingHoursHelper();
            setWorkingHours(workingHours);
        })();
    }, []);

    return (
        <>
            <NextHead
                pageTitle={pageTitle}
                pageDescription={pageDescription}
                isFTDRTheme={isFTDRTheme}
                titleLeft={titleLeft}
                shouldIndexPage={shouldIndexPage}
            />
            <div
                id="header"
                className={clsx(
                    'bg-white fixed w-full z-20',
                    !isFTDRTheme && 'h-10 md:h-16 px-3 px-0 py-3',
                    isFTDRTheme && `header-ftdr ${styles['header-ftdr']}`,
                )}
            >
                <div
                    className={clsx(
                        'flex justify-between items-center my-0 mx-auto w-full bg-white h-full',
                        !isFTDRTheme && 'max-w-5xl',
                        isFTDRTheme && styles['nav-wrapper-ftdr'],
                    )}
                >
                    <div className="w-24 md:w-56">
                        <Link href={logoUrl}>
                            {isFTDRTheme ? (
                                <a>
                                    <FTDRLogo />
                                </a>
                            ) : (
                                <a>
                                    {isDesktop ? (
                                        <AhsLogoDesk />
                                    ) : (
                                        <AhsLogoNotDesk />
                                    )}
                                </a>
                            )}
                        </Link>
                    </div>
                    {!showLogoOnly && (
                        <div className="flex w-md justify-between">
                            <div
                                className={clsx(
                                    'hidden lg:block',
                                    !isFTDRTheme && 'w-48',
                                    isFTDRTheme && 'mr-8',
                                )}
                            >
                                <RatingsBubble
                                    textColor="text-interactive-900"
                                    averageRating={averageRating}
                                    totalReviews={formatNumberWithCommas(
                                        totalReviews,
                                    )}
                                    isFTDRTheme={isFTDRTheme}
                                />
                            </div>
                            <div className="flex justify-end">
                                <HeaderBubble
                                    variant={
                                        workingHours &&
                                        isWithinWorkingHours({
                                            date: utcToZonedTime(
                                                new Date(),
                                                'America/Los_Angeles',
                                            ),
                                            workingHours,
                                        })
                                            ? 'call-line'
                                            : 'faq'
                                    }
                                    isFTDRTheme={isFTDRTheme}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export { Header };
