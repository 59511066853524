import {
    Badge,
    Text,
    Rating,
    RatingValue,
} from '@ftdr/blueprint-components-react';
import clsx from 'clsx';
import styles from './ratings-bubble.module.css';

function RatingsBubble({
    averageRating = '4.6',
    totalReviews = '57,915',
    textColor = 'text-interactive-900',
    isFTDRTheme,
}: {
    averageRating?: string;
    totalReviews?: string;
    textColor: string;
    isFTDRTheme?: boolean;
}) {
    return (
        <div
            className={clsx(
                'flex items-center justify-end',
                isFTDRTheme && styles['ratings-bubble-ftdr'],
            )}
        >
            <div className={isFTDRTheme ? 'mr-4' : 'mr-2'}>
                <Badge
                    className={clsx(
                        'h-8 w-8 text-3 font-extrabold',
                        isFTDRTheme && `badge-ftdr ${styles['badge-ftdr']}`,
                    )}
                    color="secondary"
                    size="large"
                >
                    {averageRating}
                </Badge>
            </div>
            <div>
                <Rating
                    value={Number(averageRating) as RatingValue}
                    color="secondary"
                    className={clsx('mb-1', styles['ratings'])}
                    readonly
                />
                <Text
                    variant="caption"
                    className={clsx(
                        'text-0 font-medium',
                        textColor,
                        isFTDRTheme &&
                            `text-left ${styles['ratings-text-ftdr']}`,
                    )}
                >
                    {isFTDRTheme
                        ? `${totalReviews} Reviews for In-Person Services`
                        : `${totalReviews} Reviews Nationwide`}
                </Text>
            </div>
        </div>
    );
}

export { RatingsBubble };
